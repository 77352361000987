<template>
  <table-view
    ref="tableView"
    url="/user/siteuser/listForPage"
    :filterable="false"
    :filter-form="dataForm"
    :batch-removable="isAuthed('sys_user_delete')"
    delete-url="/user/deleteById"
    :addable="isAuthed('sys_user_add')"
    :editable="isAuthed('sys_user_update')"
    :headers="headers"
    show-index
    selectable
    single
  >
    <el-form slot="search" @keyup.enter.native="query">
      <el-select clearable v-model="dataForm.siteId">
        <el-option v-for="item in siteList" :key="item.id" :label="item.siteName" :value="item.id">
        </el-option>
      </el-select>
      <el-input clearable v-model="dataForm.userName" placeholder="用户名/真实姓名"></el-input>
      <el-input clearable v-model="dataForm.phone" placeholder="手机号码"></el-input>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
  </table-view>

</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'person-siteuser',

  components: { TableView },

  data () {
    return {
      headers: [
        { id: 1, prop: 'userName', label: '用户名' },
        { id: 2, prop: 'nickName', label: '真实姓名' },
        { id: 5, prop: 'siteName', label: '工地名称', width: '300px' },
        { id: 3, prop: 'token', label: '密钥', width: '600px' },
        // { id: 3, prop: 'sex', label: '性别' },
        { id: 4, prop: 'phone', label: '手机号码' }
      ],
      dataForm: {
        siteName: ''
      },
      siteList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    query () {
      this.$refs.tableView.queryData()
    },
    async init () {
      let data = await this.$http({
        url: this.$http.adornUrl('/site/list'),
        method: 'post',
        data: {}
      })
      this.siteList = data.datas
    }
  }

}
</script>
